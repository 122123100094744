<template>
  <div class="base-select">
    <label class="base-select__label" for="input">{{ label }}</label>
    <div class="base-select__wrapper">
      <select class="base-select__select" :value="value" v-on="$listeners" ref="selectRef">
        <option v-for="(el, index) in optionList" :value="el.id" :key="el.id" :selected="isSelected(index)">
          {{ el.nickname }}
        </option>
      </select>
      <div v-if="$scopedSlots.append && !appendIconPath" class="base-select__append-content">
        <slot name="append" />
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed } from "vue";
import { selectProps } from "@/lib/helpers/propsTypes";

const props = defineProps({ ...selectProps });
const selectedOption = computed(() => props.optionList?.find(el => el.id.toString() === props.value?.toString()));
const isSelected = index => props.optionList.findIndex(i => i === selectedOption.value) === index;
</script>

<style lang="scss" scoped>
.base-select {
  &__select {
    background-repeat: no-repeat, repeat;
    background-position: right 10px top 50%, 0 0;
    background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg id='Component 1'%3E%3Cpath id='Vector' d='M13 6L8 11L3 6' stroke='%230053AD' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/g%3E%3C/svg%3E%0A");
  }
}
select::-ms-expand {
  padding: 2em;
  color: red;
  background: black;
}
</style>
